import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const HomePage = () => {
  return (
      <Layout>
        <div className="mainContentWidth">
        <Head title="Home"/>
        <h2>Welcome to N-1</h2>
        <div className= "citation">
            
              That which lies behind contrived being reveals an incoherent collage, rich as is beautiful. My understanding of you is a revelation in this profound complexity that has been labeled 'life'.
              <br></br>
          </div>
          <div className = "legend">Non-Linear Explosions Of The Emotive Mind. Mark Webster 2013.</div>
          <br></br>
          
          <h2>Context</h2>
          <p>
            N-1 is a research website which attempts to explore the subject of memory as a means for artistic expression. This is an evolving space that documents the sporadic unravellings of uncertain yet promising revelations. You will find notes, references and a reading log that serve to help the process of my research. For the most part, these are a means to collect information pertaining to enlighten the path. As a means to develop on these, I also write articles that take a deeper analysis of current topics entertaining my mind. These are in no way meant to be academic, indeed I eshew that style in search for a more accessible and at times perhaps more readable narrative. They are personal thoughts laid bare.
        </p>
        </div>
      </Layout>
  )
}

export default HomePage
